import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css"; // 引入swiper5.4.5版本

Vue.use(VueAwesomeSwiper /* { default options with global component } */);
import "@/assets/icon/iconfont.css"; // 引入阿里图标库
Vue.use(ElementUI); // 引入elementUI

import Print from "vue-print-nb"; // 引入打印功能
Vue.use(Print);

axios.defaults.withCredentials = true; // 跨域凭证 暂时默认
Vue.prototype.$http = axios;

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
