<template>
  <div id="apps">
    <!-- 公共网页头 -->
    <div class="all-header">
      <div class="all-top">
        <div class="top-content">
          <div class="content-left">
            <div class="left-home">
              <i class="el-icon-s-home"></i>
              <span @click="jumpIndex"
                >返回{{ homeInfo.extendColumn1 }}首页</span
              >
            </div>
            <div class="right-tel">
              <i class="iconfont icon-dianhua"></i>
              <p>
                <span>免费服务热线：</span>
                <span>{{ homeInfo.mallPhone }}</span>
              </p>
            </div>
          </div>
          <div class="content-right">
            <ul class="item">
              <li class="puclass">
                <div class="div">
                  <span v-if="username">您好,</span>
                  <p class="pInfo">{{ username }}</p>
                  <span style="margin-left: 5px"
                    >欢迎来到{{ homeInfo.extendColumn1 }}</span
                  >
                </div>
              </li>
              <li class="puclass" v-show="userShow" @click="goOut">退出登录</li>
              <li class="puclass" v-show="!userShow">
                <span @click="login">登录</span>
              </li>
              <li class="puclass" v-show="!userShow">
                <span @click="register">注册</span>
              </li>
              <li class="li-order">
                <div class="dindan">
                  <span>我的订单</span>
                  <i class="iconfont icon-xiabiao"></i>
                </div>
                <ul class="order">
                  <li
                    v-for="item in jumptext"
                    :key="item.index"
                    class="order-item"
                    @click="jumpOrder(item)"
                  >
                    {{ item.text }}
                  </li>
                </ul>
              </li>
              <li class="puclass show">
                <i class="iconfont icon-qrcode"></i>
                <span class="puclass">关注{{ homeInfo.extendColumn1 }}</span>
                <div class="imgs">
                  <div class="erweima">
                    <img :src="homeInfo.extendColumn3" alt="" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="header-content">
        <div class="logo">
          <!-- <img src="@/static/imgs/logo.png" alt="" /> -->
          <img :src="homeInfo.extendColumn2" alt="" />
        </div>
        <!-- <div class="slogo">
                  <img src="@/static/imgs/slogan.png" alt="" />
                </div> -->
        <div class="cont">
          <ul class="cont-ul">
            <li
              :class="navBgd === item.id ? 'active' : ''"
              class="cont-item"
              v-for="item in tabList"
              :key="item.id"
              @click="jumps(item)"
            >
              {{ item.type }}
            </li>
          </ul>
        </div>
      </div>

      <div class="kfqq">
        <ul>
          <li v-for="(item, index) in lxwm" :key="index">
            <a
              :href="
                'https://wpa.qq.com/msgrd?v=3&uin=' + item + '&site=qq&menu=yes'
              "
              target="_blank"
            >
              <!--<a href="https://wpa.qq.com/msgrd?v=3&uin=2851167917&site=qq&menu=yes" target="_blank">-->
              <!--<img src="../static/imgs/QQ.png" alt="">-->
              <div class="img"></div>
              <div class="text">在线咨询</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import print from "../api/interface/paintings";
import api from "../api/index/index";
import qs from "qs";
import { removeusername } from "../api/myAuth";
import { mapMutations, mapState } from "vuex";

export default {
  inject: ["reload"],
  props: { homeInfo: Object },
  data() {
    return {
      cont: [
        {
          type: "报价",
          id: 0,
        },
        // {
        //   type: "印刷报价",
        //   id: 1,
        // },
        {
          type: "会员中心",
          id: 2,
        },
        {
          type: "关于我们",
          id: 3,
        },
        {
          type: "完稿须知",
          id: 4,
        },
        {
          type: "网站管理",
          id: 5,
        },
      ],
      phone: "",
      navBgd: 0, //标题样式

      username: "", //用户名
      userShow: false,
      showAdmin: true,
      pageSize: 100,
      pageNumber: 1,
      custlevel: "",

      jumptext: [
        {
          index: 0,
          text: "所有订单",
        },
        {
          index: 1,
          text: "生产中",
        },
        {
          index: 2,
          text: "生产完成",
        },
      ],

      //客服QQ
      lxwm: {
        extendColumn1: "", //QQ1
        extendColumn2: "", //QQ2
        extendColumn3: "", //QQ3
      },
      userObj: {},
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    tabList() {
      if (this.showAdmin) {
        return this.cont;
      } else {
        return this.cont.slice(0, 4);
      }
    },

    // navBgd(){
    //     console.log('vuex实现兄弟组件之间传值_底部链接改变头部导航样式',this.$store.state.navBgd)
    //     this.navBgd = this.$store.state.navBgd;
    // }
  },
  mounted() {
    // console.log(this.tabList);
    let nav = window.sessionStorage.getItem("nav");
    console.log("导航头", nav);
    if (nav) {
      console.log("执行了");
      this.navBgd = Number(nav);
    } else {
      this.navBgd = 0;
    }

    console.log(
      "vuex实现兄弟组件之间传值_底部链接改变头部导航样式",
      this.$store.state.navBgd
    );
    if (this.$store.state.navBgd === 3) {
      this.navBgd = this.$store.state.navBgd;
      // window.sessionStorage.setItem("nav", this.navBgd);
    }
    // if (this.navBgd == 0) {
    //   this.$router.push({ path: "index" }); //首页
    // }
    this.info();
  },
  created() {
    // console.log("执行钩子");
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    this.userObj = JSON.parse(window.sessionStorage.getItem("userInfo"));
    if (obj === null) {
      // 没有登录信息和不是管理员不显示网站管理模块
      // console.log("执行了判断");
      this.showAdmin = false;
    } else {
      if (obj.name) {
        this.username = obj.name;
      } else {
        console.log("执行了判断会员名");
        this.username = obj.username;
      }
      if (obj.userType === 1) {
        //(0为游客，1为管理员)
        this.showAdmin = true;
      } else {
        this.showAdmin = false;
      }
    }
    // console.log(getusername());
    this.initUserInfo(this.userObj);
    // console.log("获取vuex内的数据", this.userInfo);
    this.navBgd = 0;
    if (this.userObj) {
      this.userShow = true;
      this.phone = this.userObj.phone;
      // this.init();
    } else {
      this.userShow = false;
    }
    this.info();
  },
  methods: {
    info() {
      this.$http.get("/mallaboutus/listAll").then((res) => {
        console.log("联系我们", res);
        if (res.data.code == 200) {
          //   this.lxwmId = res.data.data[0].id
          //   this.lxwm.aboutUsContent = res.data.data[0].aboutUsContent
          this.lxwm.extendColumn1 = res.data.data[0].extendColumn1;
          this.lxwm.extendColumn2 = res.data.data[0].extendColumn2;
          this.lxwm.extendColumn3 = res.data.data[0].extendColumn3;
        } else {
          this.$message.error("查询失败");
        }
      });
    },

    ...mapMutations(["setUserInfo"]),
    initUserInfo(data) {
      this.$store.commit("setUserInfo", data);
    },
    jumpOrder(item) {
      console.log("跳转到订单", item);
      let jumptext = "";
      if (item.text == "所有订单") {
        jumptext = "";
      } else {
        jumptext = item.text;
      }
      this.$router.push({
        name: "Personal",
        params: { jump: 2, jumptext: jumptext },
      });
    },
    // 退出登录
    goOut() {
      // console.log("头部退出登录");
      removeusername();
      window.sessionStorage.removeItem("currentInfo");
      window.sessionStorage.removeItem("userInfo");
      window.sessionStorage.removeItem("nav");
      window.sessionStorage.removeItem("info");
      api.loginOut().then((res) => {
        console.log("退出成功", res);
        if (res.success) {
          this.$message.success("退出成功");
          this.reload();
          this.$router.push({ path: "/login" });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 调试接口
    debugging() {
      let data = { enterpriseId: "216653205612793856", id: "1020" };
      print.paintingData(qs.stringify(data)).then((res) => {
        console.log(res);
      });
    },
    // 返回首页
    jumpIndex() {
      this.$router.push({ path: "/" });
      this.navBgd = 0;
    },
    jumps(item) {
      window.sessionStorage.setItem("nav", item.id);
      if (item) {
        this.navBgd = item.id; // 导航切换
      } else {
        this.navBgd = 0; // 初始化选择第一个大类
      }
      window.sessionStorage.removeItem("leftMenu");
      window.sessionStorage.removeItem("topMenu");
      console.log(item);
      this.$store.dispatch("setNavBgd", item.id);

      switch (this.navBgd) {
        case 0:
          this.$router.push({ path: "Quotation" }); //首页
          break;
        // case 1: // 印刷报价
        //   console.log("印刷报价");
        //   this.$router.push({ name: "Quotation" });
        //   break;
        case 2:
          this.$router.push({ name: "Personal", params: { id: 0 } }); // 会员中心 (params传参不会在地址栏上显示)
          break;
        case 3:
          console.log("关于我们");
          this.$router.push({ name: "about", query: { id: 5, checked: 0 } });
          break;
        case 4:
          console.log("完稿须知");
          this.$router.push({ name: "about", query: { id: 0, checked: 1 } });
          break;
        case 5:
          console.log("网站管理");
          this.$router.push({
            name: "WebAdmin",
            params: { id: 0, checked: 1 },
          });
          break;
      }
    },
    // 登录页跳转
    login() {
      this.$router.push({ path: "login" });
    },
    // 注册页跳转
    register() {
      this.$router.push({ path: "register" });
    },
  },
};
</script>
<style lang="scss">
* {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  //   text-align: center;
  //   color: #2c3e50;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

.all-header {
  width: 100%;
  background-color: #fff;

  .all-top {
    position: relative;

    font-size: 15px;
    color: #888;
    width: 100%;
    // overflow: hidden;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #eee;
    background: #f5f5f5 none repeat scroll 0% 0%;

    .top-content {
      margin: 0 auto;
      height: 35px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content-left {
        height: 35px;
        display: flex;
        justify-content: space-between;

        .left-home {
          margin-right: 20px;
          cursor: pointer;

          i {
            font-size: 14px;
            color: #68676c;
            margin-right: 5px;
          }
        }

        .left-home:hover {
          color: #058af2;
        }

        .right-tel {
          display: flex;

          i {
            font-size: 14px;
            margin-right: 5px;
          }
        }
      }

      .content-right {
        height: 35px;

        .item {
          display: flex;
        }

        .puclass {
          display: inline-block;
          padding: 0 10px;
        }

        .li-order {
          display: block;
          padding: 0 10px;
          cursor: pointer;
          position: relative;

          .dindan {
            text-align: center;

            span {
              display: inline-block;
            }

            i {
              display: inline-block;
              width: 20px;
              font-size: 8px;
            }
          }

          .order {
            width: 100px;
            position: absolute;
            left: 0;
            text-align: center;
            display: none;
            background-color: #fff;
            z-index: 999;
          }
        }

        .li-order:hover {
          background-color: #fff;
        }

        .li-order:hover .dindan {
          color: #058af2;
        }

        .li-order:hover .order {
          display: block;
        }

        .order .order-item:hover {
          color: #058af2;
        }
        .puclass .div {
          display: flex;
          justify-content: flex-end;
          .pInfo {
            display: inline-block;
            color: #058af2;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .puclass.show {
          // width: 108px;
          text-align: center;

          i {
            font-size: 16px;
          }

          .imgs {
            display: none;

            .erweima {
              margin: 0 auto;
              width: 108px;
              height: 108px;

              img {
                display: inline-block;
                max-width: 108px;
                max-height: 108px;
                // width: 100%;
              }
            }
          }
        }

        .puclass.show:hover .imgs {
          display: block;
        }

        .puclass:hover {
          cursor: pointer;
          color: #058af2;
          background-color: #fff;
        }
      }
    }
  }

  .header-content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    .logo {
      height: 90px;

      img {
        display: inline-block;
        height: 90px;
      }
    }

    .logo,
    .slogo {
      margin-right: 30px;
      width: 44%;
    }

    .cont-ul {
      display: flex;

      li {
        padding: 8px 25px;
        margin-right: 15px;
        border-radius: 5px;
        font-size: 16px;
        height: 35px;
        line-height: 35px;
        font-weight: bold;
      }

      .cont-item:hover {
        color: #fff;
        background-color: #058af2;
        cursor: pointer;
      }
    }
  }
}

.active {
  color: #fff;
  background-color: #058af2;
}

/*客服QQ*/
.kfqq {
  position: fixed;
  z-index: 10003;
  right: 0px;
  /*background: #0289f2;*/
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: white;
  box-shadow: #cccccc 0 0 10px;

  ul {
    li {
      padding: 10px 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid #e6e6e6;

      a {
        text-decoration: none;
      }

      .img {
        width: 36px;
        height: 36px;
        margin: auto;
        display: block;
        /*background: url("../static/imgs/QQ.png") no-repeat;*/
        background: url("../static/imgs/floatbgc.png") no-repeat;
        /*background-size: 40px 40px;*/
        background-position: 0 -144px;
      }

      .text {
        color: #000;
        margin-top: 5px;
        font-size: 12px;
      }
    }

    li:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    li:hover {
      background-color: white;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      .text {
        color: #0289f2;
        margin-top: 5px;
        font-size: 12px;
      }

      .img {
        width: 36px;
        height: 36px;
        margin: auto;
        display: block;
        /*background: url("../static/imgs/QQ (1).png") no-repeat;*/
        background: url("../static/imgs/floatbgc.png") no-repeat;
        background-position: -36px -144px;
        /*background-size: 40px 40px;*/
      }
    }

    li:first-child:hover {
      border-bottom-left-radius: 0px;
    }
  }
}
</style>
