<template>
  <div id="app">
    <!-- 公共网页头 -->
    <!-- <div class="all-header">
      <div class="all-top">
        <div class="top-content">
          <div class="content-left">
            <div class="left-home">
              <i class="el-icon-s-home"></i>
              <span>返回印友云印首页</span>
            </div>
            <div class="right-tel">
              <i class="iconfont icon-dianhua"></i>
              <p>
                <span>免费服务热线：</span>
                <span>025-84311196</span>
              </p>
            </div>
          </div>
          <div class="content-right">
            <ul class="item">
              <li class="puclass">
                <span> 您好，欢迎来到印友云印！</span>
              </li>
              <li class="puclass">
                <span>登录</span>
              </li>
              <li class="puclass">
                <span>注册</span>
              </li>
              <li class="li-order">
                <div class="dindan">
                  <span>我的订单</span>
                  <i class="iconfont icon-xiabiao"></i>
                </div>
                <ul class="order">
                  <li class="order-item">所有订单</li>
                  <li class="order-item">待发货</li>
                  <li class="order-item">待收货</li>
                  <li class="order-item">待评价</li>
                </ul>
              </li>
              <li class="puclass show">
                <i class="iconfont icon-qrcode"></i>
                <span class="puclass">关注印友</span>
                <div class="imgs">
                  <div class="erweima">
                    <img src="@/static/imgs/img_qrCode_app.jpg" alt="" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="header-content">
        <div class="logo">
          <img src="@/static/imgs/logo.png" alt="" />
        </div>
        <div class="slogo">
          <img src="@/static/imgs/slogan.png" alt="" />
        </div>
        <div class="cont">
          <ul class="cont-ul">
            <li
              :class="item.id === 0 ? 'active' : ''"
              class="cont-item"
              v-for="item in cont"
              :key="item.id"
            >
              {{ item.type }}
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <public-header :homeInfo="homeInfo" v-if="isRouterAlive" />
    <div id="nav">
      <router-link to="/"></router-link>
    </div>
    <!-- 内容显示部分 -->
    <router-view />
    <public-footer :homeInfo="homeInfo" />
  </div>
</template>
<script>
import PublicHeader from "../src/components/header.vue";
import PublicFooter from "../src/components/footer.vue";
import api from "./api/index/index";
import { mapMutations } from "vuex";
import { username } from "./api/myAuth";

export default {
  components: {
    PublicHeader,
    PublicFooter,
  },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      cont: [
        {
          type: "首页",
          id: 0,
        },
        {
          type: "印刷报价",
          id: 1,
        },
        {
          type: "会员中心",
          id: 2,
        },
        {
          type: "关于我们",
          id: 3,
        },
        {
          type: "完稿须知",
          id: 4,
        },
      ],
      homeInfo: {},
      isRouterAlive: true,
    };
  },
  created() {
    this.init();
    let nav = window.sessionStorage.getItem("nav");
    if (nav) {
      this.getNavBgd(nav);
    }
  },
  methods: {
    ...mapMutations(["getNavBgd"]),
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
    // 初始化头部信息（商城信息）
    init() {
      api
        .getHomeInfo()
        .then((res) => {
          console.log("获取店铺信息", res);
          if (res.success) {
            this.homeInfo = res.data.list[0];
            // 存储当前商城信息到浏览器
            window.sessionStorage.setItem(
              "shoppingInfo",
              JSON.stringify(res.data.list[0])
            );
            username(res.data.list[0]);
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
  },
};
</script>
<style lang="scss">
.el-radio__inner {
  border: 1px solid #909399 !important;
}
.el-checkbox__inner {
  border: 1px solid #909399 !important;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #409eff !important;
  background: #409eff !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409eff !important;
  border-color: #409eff !important;
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #f2f6fc !important;
  border-color: #909399 !important;
}
</style>
