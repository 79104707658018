import request from "@/api/request";
// const basUrl = "http://cpsapi.yy12365.com";
// 资源列表
function paintingData(data) {
  return request({
    method: "post",
    url: `/interface/wechat/product/paintings`,
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
}
// 查询用户列表
function queryUser(pageNumber, pageSize, data) {
  return request({
    method: "get",
    url: `/user?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data,
  });
}
// 查询用户信息

function queryUserInfo(data) {
  return request({
    method: "get",
    url: `/user/getInfoByMobile`,
    data,
  });
}
export default {
  paintingData,
  queryUser,
  queryUserInfo,
};
