import axios from "axios";
import router from "@/router";
import { Message } from "element-ui";
import { getToken, removeAll } from "./myAuth";
import baseUrl from "./picture";

const service = axios.create({
  baseURL: baseUrl.baseUrl, // 公共域名
  timeout: 10000, // 延时操作
  withCredentials: true,
});

// axios.defaults.baseURL  = "http://192.168.0.113:8080/cps";//配置默认
// axios.defaults.baseURL  = "http://hzyw.yy12365.com/cps";
axios.defaults.baseURL = baseUrl.baseUrl;

service.interceptors.request.use(
  (config) => {
    // console.log("config", config);
    // const token = getToken();
    // const cookie = window.sessionStorage.getItem("cookie");
    // console.log("cookie", cookie);
    // if (cookie) {
    // config.headers["Cookie"] = cookie;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // console.log("请求头内容", response);
    // 每次请求都会在这里走一遍
    if (res.code === 20002 || res.code === 401) {
      removeAll();
      router.push("/login");
    }
    return res;
  },
  (error) => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

// 在提交请求前, 对请求内容做一个处理

function http(options) {
  var defaultHeaders = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  var myOptions = {
    method: options.method,
    url: options.url,
    headers: options.headers ? options.headers : defaultHeaders,
  };
  if (options.data) {
    // 过滤数据
    if (options.data.constructor === FormData) {
      // 当上传数据为文件格式的时候（允许上传）富文本上传图片
      myOptions.data = options.data;
    }
    if (Object.keys(options.data).length > 0) {
      // 如果是get请求,则请求体的key 变为 params
      if (options.method === "GET") {
        myOptions.params = options.data;
      } else {
        myOptions.data = options.data;
      }
    }
  }

  // 对请求类型做一个处理content-type
  return service(myOptions);
}

export default http;
