<template>
  <div>
    <div class="top">
      <div class="ban">
        <div class="featureDiv">
          <div :key="item.id" class="server" v-for="item in boot">
            <i :class="item.icon"></i>
            <div class="right">
              <p>{{ item.text }}</p>
              <p>{{ item.info }}</p>
            </div>
          </div>
        </div>
        <div class="descDiv">
          <div class="logDiv">
            <!-- <img src="@/static/imgs/logo.png" alt="" /> -->
            <img :src="homeInfo.extendColumn2" alt="" />
            <p>
              <i class="iconfont icon-dianhua"></i>
              <span>{{ homeInfo.mallPhone }}</span>
            </p>
          </div>
          <div :key="item.id" class="linksDiv" v-for="item in liList">
            <ul>
              <li>{{ item.text }}</li>
              <li
                :key="items.id"
                v-for="items in item.children"
                @click="jump(item, items)"
              >
                {{ items.text }}
              </li>
            </ul>
          </div>
          <div class="telDiv">
            <!-- <div class="left">
                          <img src="@/static/imgs/img_qrCode_app.jpg" alt="" />
                          <p>印友云印APP</p>
                        </div> -->
            <div class="right">
              <!-- <img src="@/static/imgs/img_qrCode_weChat.jpg" alt="" /> -->
              <img :src="homeInfo.extendColumn3" alt="" />
              <p>{{ homeInfo.extendColumn1 }}微信</p>
            </div>
          </div>
        </div>
        <div class="bottomDiv">
          <p>
            Copyright ©2016- <span>2023</span> 	{{ homeInfo.extendColumn5 }} ,All Rights Reserved
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >{{ homeInfo.extendColumn6 }}</a
            >
          </p>
          <p>技术支持：和众印网南京信息科技有限公司</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  props: { homeInfo: Object },
  data() {
    return {
      navBgd: 0,
      boot: [
        {
          id: 0,
          icon: "iconfont icon-zhiliang",
          text: "质量周期",
          info: "2天质量处理周期",
        },
        {
          id: 1,
          icon: "iconfont icon-huoche",
          text: "免费配送",
          info: "定点城市区域免费配送",
        },
        {
          id: 2,
          icon: "iconfont icon-kefu",
          text: "货到付款",
          info: "货到付款",
        },
        {
          id: 3,
          icon: "iconfont icon-fuwu",
          text: "热线服务",
          info: "7*24小时客户热线服务",
        },
      ],
      liList: [
        {
          id: 0,
          text: "操作指南",
          children: [
            {
              id: 0,
              text: "订印流程",
            },
            {
              id: 1,
              text: "完稿须知",
            },
          ],
        },
        {
          id: 2,
          text: "支付方式",
          children: [
            {
              id: 0,
              text: "支付宝支付",
            },
            {
              id: 1,
              text: "银行转账",
            },
          ],
        },
        {
          id: 3,
          text: "配送方式",
          children: [
            {
              id: 0,
              text: "送货上门",
            },
            {
              id: 1,
              text: "预约自提",
            },
          ],
        },
        {
          id: 4,
          text: "售后服务",
          children: [
            {
              id: 0,
              text: "找回密码",
            },
            {
              id: 1,
              text: "退货原则",
            },
          ],
        },
        {
          id: 5,
          text: "关于我们",
          children: [
            {
              id: 0,
              text: "公司简介",
            },
            {
              id: 1,
              text: "联系我们",
            },
          ],
        },
      ],
    };
  },
  methods: {
    jump(item, items) {
      console.log(item);
      console.log(items);
      console.log(items.id);
      this.chieck = item.id;
      this.checked = items.id;
      this.navBgd = 3;
      this.reload();
      this.$store.dispatch("setNavBgd", this.navBgd);
      // this.$store.dispatch('checked',items.id)
      // this.$store.dispatch('chieck',item.id)
      this.$router.push({name: "about", query: { id: item.id, checked: items.id },});
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.top {
  width: 100%;

  .ban {
    width: 1200px;
    margin: 0 auto;

    .featureDiv {
      border-bottom: 1px solid #d9d9d9;
      width: 100%;
      display: flex;

      .server {
        box-sizing: border-box;
        display: flex;
        width: 25%;
        height: 107px;
        padding: 30px 0 30px 8.67%;

        i {
          color: #0289f2;
          font-size: 40px;
        }

        .right {
          height: 46px;
          border-left: 2px solid #0289f2;
          margin-left: 10px;
          padding-left: 10px;

          p:first-child {
            font-size: 18px;
            line-height: 18px;
            color: #0289f2;
          }

          p:last-child {
            margin-top: 11px;
            font-size: 12px;
            line-height: 14px;
            color: #4c4c4c;
          }
        }
      }
    }

    .descDiv {
      width: 100%;
      padding-top: 60px;
      display: flex;
      justify-content: space-around;

      .logDiv {
        text-align: center;

        img {
          display: block;
          max-width: 184px;
          max-height: 65px;
          margin: 0 auto;
        }

        p {
          color: #666;

          i {
            font-size: 16px;
          }

          span {
            padding-left: 10px;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }

      .linksDiv {
        display: flex;
        /*width: 825px;*/

        ul {
          /*width: 14.2%;*/
          justify-content: space-around;

          li:first-child {
            font-size: 14px;
            line-height: 28px;
            color: #ccc;
          }

          li {
            text-align: center;
            font-size: 12px;
            line-height: 20px;
            color: #666;
            white-space: nowrap;
          }

          li:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          li:first-child:hover {
            cursor: pointer;
            text-decoration: none;
          }
        }
      }

      .telDiv {
        display: flex;
        text-align: center;

        .left,
        .right {
          width: 100px;
          height: 100px;

          img {
            display: block;
            max-height: 100px;
            max-width: 100px;
            margin: 0 auto;
          }

          p {
            font-size: 12px;
            text-align: center;
            color: #666;
          }
        }
      }
    }

    .bottomDiv {
      padding: 30px 0;
      width: 100%;
      text-align: center;

      ul {
        li {
          display: inline-block;
          font-size: 12px;
          line-height: 12px;
          color: #666;

          a {
            font-size: 12px;
            padding: 0 5px;
            text-align: center;
            color: #666;
          }
        }

        li:hover a {
          text-decoration: underline;
        }
      }

      p {
        font-size: 12px;
        line-height: 22px;
        color: #4c4c4c;
        text-align: center;
      }
    }
  }
}
</style>
