import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    currentInfo: {},
    navBgd: 0,
    chieck: 0,
    checked: 0,
  },
  mutations: {
    setUserInfo(state, getUser) {
      if (getUser === null) {
        state.userInfo = {};
      } else {
        state.userInfo = getUser;
      }
    },
    getCurrentInfo(state, info) {
      state.currentInfo = info;
    },
    //vuex实现兄弟组件之间传值_底部链接改变头部导航样式
    getNavBgd(state, num) {
      state.navBgd = num;
    },
    // getchieck(state, num) {
    //     state.chieck = num;
    // },
    // getchecked(state, num) {
    //     state.checked = num;
    // }
  },
  actions: {
    setNavBgd({ commit }, num) {
      commit("getNavBgd", num);
    },
    // chieck({commit, state}, num) {
    //     commit('getchieck', num)
    // },
    // checked({commit, state}, num) {
    //     commit('getchecked', num)
    // }
  },
  modules: {},
});
