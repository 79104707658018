import request from "@/api/request";
// const baseURL = "http://cpsapi.yy12365.com";
// 获取首页banner图
function getAllData(pageNumber, pageSize, data) {
  return request({
    method: "get",
    url: `/mallbanner?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data,
  });
}
// 获取登录商城信息
function getHomeInfo(data) {
  return request({
    method: "get",
    url: "/home/homeUsefasterInfo",
    data,
  });
}
// 获取文章列表（首页公告）
function getArticle(pageNumber, pageSize, data) {
  return request({
    method: "get",
    url: `/mallarticlemanage?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data,
  });
}
// 获取网站订单列表
function getOrder(pageSize, pageNumber, data) {
  return request({
    method: "get",
    url: `/home/orderInfo?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    data,
  });
}
// 检测手机号是否存在
function inspectPhones(phone, data) {
  return request({
    method: "get",
    url: `/home/determineWhetherTheUserExists?mobile=${phone}`,
    data,
  });
}
// 退出登录
function loginOut(data) {
  return request({
    method: "post",
    url: `/login/logout`,
    data,
  });
}
export default {
  inspectPhones,
  getAllData,
  getHomeInfo,
  getArticle,
  getOrder,
  loginOut,
};
