import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/index",
    component: Home,
  },
  // 首页
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/index/index.vue"),
    meta: {
      title: "报价商城",
    },
  },
  // 登录页
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/login.vue"),
    meta: {
      title: "登录",
    },
  },
  // 注册页
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register/register.vue"),
    meta: {
      title: "注册",
    },
  },
  // 会员中心
  {
    path: "/personal",
    name: "Personal",
    component: () => import("../views/Personal/personal.vue"),
    meta: {
      title: "会员中心",
    },
  },
  //印刷报价
  {
    path: "/quotation",
    name: "Quotation",
    component: () => import("../views/Quotation/quotation.vue"),
    meta: {
      title: "印刷报价",
    },
  },
  //关于我们
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutUs/about.vue"),
    meta: {
      title: "关于我们",
    },
  },
  //完稿须知
  {
    path: "/notice",
    name: "notice",
    component: () => import("../views/Notice/notice.vue"),
    meta: {
      title: "完稿须知",
    },
  },
  {
    path: "/webAdmin",
    name: "WebAdmin",
    component: () => import("../views/webAdmin/index.vue"),
    meta: {
      title: "网站管理",
    },
  },
  //首页公告
  {
    path: "/proclamation",
    name: "Proclamation",
    component: () => import("../views/Proclamation/proclamation.vue"),
    meta: {
      title: "公告",
    },
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  routes,
});

export default router;
