//wxchunyang.cn春阳地址
// const picUrl = "http://erp.wxchunyang.cn/cps/"; // 图片反显
// const BrowseUrl = "https://obs.wxchunyang.cn/"; //  obs上传文件
// const richUrl = "http://mallapi.wxchunyang.cn/file"; // 富文本上传文件
// const baseUrl = "http://mallapi.wxchunyang.cn"; // 公共地址
//yy12365.com印友地址
// const picUrl = "http://erp.smartprint.cn/cps/"; // 图片反显
// const BrowseUrl = "https://obs.smartprint.cn/"; //  obs上传文件
// const richUrl = "http://portalapi.smartprint.cn/file"; // 富文本上传文件
// const baseUrl = "http://portalapi.smartprint.cn"; // 公共地址
// const sfUrl = "http://sfapi.yyszhyyjy.com"; // 顺丰api地址

// const picUrl = "http://erp.yyszhyyjy.com/cps/"; // 图片反显
// const BrowseUrl = "https://obs.yyszhyyjy.com/"; //  obs上传文件
// const richUrl = "http://portalapi.yyszhyyjy.com/file"; // 富文本上传文件
// const baseUrl = "http://portalapi.yyszhyyjy.com"; // 公共地址
// const sfUrl = "http://sfapi.yyszhyyjy.com"; // 顺丰api地址

const picUrl = "http://erp.jsdny.cn/cps/"; // 图片反显
const BrowseUrl = "https://obs.jsdny.cn/"; //  obs上传文件
const richUrl = "http://api.jsdny.cn/file"; // 富文本上传文件
const baseUrl = "http://api.jsdny.cn"; // 公共地址
const sfUrl = "http://sfapi.jsdny.cn"; // 顺丰api地址

export default {
  picUrl,
  BrowseUrl,
  richUrl,
  baseUrl,
  sfUrl
};
