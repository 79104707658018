const TOKEN_NAME = "yinyou-token";
const USERINFO = "yinyou-userInfo";
// 注意!!! localStorage 只能存储字符串

// 存token
export function setToken(token) {
  window.localStorage.setItem(TOKEN_NAME, token);
}

// 存userinfo
export function setUserInfo(obj) {
  window.localStorage.setItem(USERINFO, JSON.stringify(obj));
}

// 取token
export function getToken() {
  return window.localStorage.getItem(TOKEN_NAME);
}

// 取用户信息
export function getUserInfo() {
  return JSON.parse(window.localStorage.getItem(USERINFO));
}

// 删除token
export function removeToken() {
  return window.localStorage.removeItem(TOKEN_NAME);
}

// 删除用户
export function removeUserInfo() {
  return window.localStorage.removeItem(USERINFO);
}

// 存用户
export function username(obj) {
  window.sessionStorage.setItem(USERINFO, JSON.stringify(obj));
}

// 取用户
export function getusername() {
  return JSON.parse(window.sessionStorage.getItem(USERINFO));
}

// 删除用户
export function removeusername() {
  return window.sessionStorage.removeItem(USERINFO);
}

// 清空全部（退出登录）
export function removeAll() {
  removeToken();
  removeUserInfo();
  removeusername();
}
